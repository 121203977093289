import $ from 'jquery';
import 'slick-carousel';


//Check Arrows/dots for slider
function checkDotDesti() {
    let destiContainer = $('.destination-container')
    if (destiContainer.hasClass('with-dots') &&
        destiContainer.hasClass('with-slider')) {
        return '.with-slider .destination-image-container .slider-nav'
    }
    return ''
}

function checkArrowDesti() {
    let destiContainer = $('.destination-container')
    if (destiContainer.hasClass('with-arrow') &&
        destiContainer.hasClass('with-slider')) {
        return true

    }
    return false
}


function checkAutoplayDesti() {
    if (checkArrowDesti && checkDotDesti !== '') {
        return true
    }
    return false
}

//-------------------------------------------------------------//

//Check hébergement slider
let hebergementContainer = $('.modal-block')
let body = document.querySelector('body')

function checkArrowHeber() {
    if (hebergementContainer.hasClass('with-arrow') &&
        hebergementContainer.hasClass('with-slider')) {
        return true
    }
    return false
}

function hebergement() {
    if (hebergementContainer.hasClass('with-slider') && hebergementContainer.hasClass('with-dots') && !body.classList.contains('mobile')) {
        hebergementContainer.removeClass('with-dots')
        hebergementContainer.addClass('with-arrow')
    }
    return checkArrowHeber()
}

function checkDotHeber() {
    let hebergementContainer = $('.modal-block')
    if (hebergementContainer.hasClass('with-dots') &&
        hebergementContainer.hasClass('with-slider') &&
        $('body').hasClass('mobile')) {
        return '.with-slider > .modal-block-title .slider-nav'
    }

    return ''
}

function checkAutoplayHeber() {
    if (checkArrowHeber && checkDotHeber !== '') {
        return true
    }
    return false
}

//-------------------------------------------------------------//

//Check activity slider

function checkDotActivity() {
    let activityContainer = $('.activity-list')
    if (activityContainer.hasClass('with-dots')) {
        return '.two-lines .slider-nav'
    }
    return ''
}

function checkArrowActivity() {
    let activityContainer = $('.activity-list')
    if (activityContainer.hasClass('with-arrow')) {
        return true
    }
    return false
}


export function startSlider() {

    ///***  SLICK SLIDERS  ***///

    $('.slider').slick({
        infinite: true,
        slidesToShow: 1,
        dots: true,
        arrows: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        appendDots: $('.slider-container .slider-nav', this),
    });

    // *** Destination ***
    $('.with-slider > .destination-image-container > .destination-image').slick({
        infinite: true,
        slidesToShow: 1,
        dots: true,
        arrows: checkArrowDesti(),
        speed: 500,
        autoplay: checkAutoplayDesti(),
        autoplaySpeed: 5000,
        appendDots: (checkDotDesti()),
    });

    // *** Hébergement ***
    $('.hotel-single-slider').slick({
        infinite: true,
        slidesToShow: 1,
        arrows: hebergement(),
        speed: 800,
        dots: true,
        autoplay: checkAutoplayHeber(),
        autoplaySpeed: 2500,
        pauseOnHover: false,
        pauseOnFocus: false,
        appendDots: (checkDotHeber()),
        prevArrow: '<button type="button" class="slick-prev slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="19.65" height="34.371" viewBox="0 0 19.65 34.371">\n' +
            '  <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M17.174,23.375l13-12.995A2.456,2.456,0,0,0,26.7,6.911L11.966,21.635a2.451,2.451,0,0,0-.072,3.387l14.8,14.826a2.456,2.456,0,1,0,3.479-3.469Z" transform="translate(-11.251 -6.194)" fill="#fff"/>\n' +
            '</svg>\n</button>',
        nextArrow: '<button type="button" class="slick-next slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="19.65" height="34.371" viewBox="0 0 19.65 34.371">\n' +
            '  <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M17.174,23.375l13-12.995A2.456,2.456,0,0,0,26.7,6.911L11.966,21.635a2.451,2.451,0,0,0-.072,3.387l14.8,14.826a2.456,2.456,0,1,0,3.479-3.469Z" transform="translate(-11.251 -6.194)" fill="#fff"/>\n' +
            '</svg>\n</button>'
    });

    // *** Programme ***
    if ($('body').hasClass('tablet') || $('body').hasClass('mobile')) {
        console.log('programme')
        $('.slider-responsive-programme').slick({
            infinite: false,
            slidesToShow: 1,
            dots: true,
            arrows: false,
            speed: 500,
            appendDots: $('.slider-responsive-programme-container .slider-nav', this)
        });
    }

    // *** Activity ***

    //Activity two slide (PLAZA IMMO)
    $('.in-line-slider > .activity-list').slick({
        infinite: true,
        slidesToShow: 2,
        dots: true,
        arrows: checkArrowActivity(),
        speed: 800,
        autoplay: false,
        autoplaySpeed: 2500,
        pauseOnHover: false,
        pauseOnFocus: false,
        appendDots: (checkDotActivity()),
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }],
    });

    //Activity slide only mobile
    if ($('body').hasClass('mobile')) {
        $('.activity-list.with-slider-mobile').slick({
            infinite: true,
            slidesToShow: 1,
            dots: true,
            arrows: checkArrowActivity(),
            speed: 800,
            autoplay: false,
            autoplaySpeed: 2500,
            pauseOnHover: false,
            pauseOnFocus: false,
            appendDots: $(checkDotActivity())

        });
    }


    if ($('body').hasClass('tablet-large') || $('body').hasClass('tablet') || $('body').hasClass('mobile')) {
        $('.slider-responsive').slick({
            infinite: false,
            slidesToShow: 1,
            dots: true,
            arrows: false,
            speed: 500,
            appendDots: $('.slider-responsive-container .slider-nav', this)
        });
    }


    $(window).on('load', function () {
        if ($('body').hasClass('tablet-large') || $('body').hasClass('tablet') || $('body').hasClass('mobile')) {
            $('.slider-responsive').slick('refresh');
        }

        if ($('body').hasClass('mobile')) {

            $('.slider-responsive-programme').slick('refresh');

            if ($('.slider-responsive-programme').length) {
                var maxHeight = 0;
                $('.slider-responsive-programme .slick-slide').each(function () {
                    var data_height = $(this).height();
                    if (data_height > maxHeight) {
                        maxHeight = data_height;
                    }
                });
                $(this).find('.slick-slide').css('height', maxHeight)
            }
        }
    });
};
